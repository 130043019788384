// use uuids for the scratch-cache to identify parts that belong together
import { v4 as uuidv4 } from 'uuid';
import 'regenerator-runtime/runtime'

import {url_params} from './url-params.js';

class CodeCache {
    constructor(url, token) {
        let uuid = url_params.get('uuid');
        if (!uuid) {
            console.log("[CodeCache] no uuid param provided, generating one for this session");
            uuid = uuidv4();
        }
        console.log(`[CodeCache] Decoded cache uuid: ${uuid}`);
        this.get_uuid = () => {
            return uuid;
        }

        const headers =  { "Content-type":  "application/json; charset=UTF-8",
            "Authorization": `Bearer ${token}` }

        this.set_request = async (endpoint, data) => {
            const body = {...data, uuid: uuid, timestamp: Date.now() }
            return fetch(`${url}/${endpoint}`,
                { method: "POST",
                  body: JSON.stringify(body),
                  headers: headers})
                  .catch((exception) => { console.error(exception)});
        };
    };

    set_dom(dom) {
        this.set_request('dom', {dom: dom} );
    }

    set_cursor(cursor) {
        this.set_request('cursor', {cursor: cursor} );
    }

    set_thumbnail(thumbnail) {
        this.set_request('thumbnail', {thumbnail: thumbnail} );
    }
};

const log_server = decodeURIComponent(url_params.get('scratch_log_server')) || 'http://172.16.0.3:3000/scratch_log'
console.log(`logging data to ${log_server}`);
export const code_cache = new CodeCache(log_server, "C8Y8cJ96er2fGKGZndEMpN3qsPVUGtEVFb1Q");
