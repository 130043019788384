class UrlParams {
    constructor() {
        this.params = {};
    };

    get(key) {
        console.log('params: ', window.location.href);

        if (!this.params[key]) {
            const regex = new RegExp(String.raw`[?&]${key}=([^&]*)&?`);
            const key_match = window.location.href.match(regex);
            this.params[key] = key_match ? decodeURIComponent(key_match[1]) : null;
        }

        console.log("[UrlParams]: ", key, this.params);
        return this.params[key];
    };

}

export const url_params = new UrlParams();
